import { useQuery } from "react-query"
import { v4 as uuidv4 } from "uuid"
import { findNotificationsByUserId, getUnreadNotificationsCount } from "services/notifications.service"
import { FindNotificationByUserIdResponse, GetUnreadNotificationsCountResponse } from "services/types/notifications"

const REFETCH_INTERVAL = 10000 // 10 seconds

export const useNotificationsRefresh = (userId: string) => {
	return useQuery<FindNotificationByUserIdResponse>(
		["notifications", userId, "findNotificationByUserId"],
		async () => {
			const { data } = await findNotificationsByUserId({ request_id: uuidv4(), user_id: userId })

			return data
		},
		{ refetchInterval: REFETCH_INTERVAL }
	)
}

export const useGetUnreadNotificationsCount = (userId: string) => {
	return useQuery<GetUnreadNotificationsCountResponse>(
		["notifications", userId, "unreadNotificationsCount"],
		async () => {
			const { data } = await getUnreadNotificationsCount({ request_id: uuidv4(), user_id: userId })

			return data
		},
		{ refetchInterval: REFETCH_INTERVAL }
	)
}

import React from "react"
import { Avatar, Badge, Col, Row, Tooltip } from "antd"
import { v4 as uuidv4 } from "uuid"
import { DeleteNotificationIcon, UnreadNotificationIcon } from "assets/icons/Notification"
import { UserOutlined } from "@ant-design/icons"
import "./Notification.scss"
import { NotificationMessage } from "./NotificationMessage"
import { NotificationDaysAgo } from "./NotificationDaysAgo"
import { NotificationAction } from "./NotificationAction"
import { NotificationStatus, PodpalNotification } from "services/types/notifications"
import { useNotificationsHook } from "hooks/useNotificationsHook"

interface PodpalNotificationProps {
	notification: PodpalNotification
	userId: string
}
const Notification: React.FC<PodpalNotificationProps> = ({ notification, userId }) => {
	const [showDeleteButton, setShowDeleteButton] = React.useState(false)
	const [showUnreadTooltip, setShowUnreadTooltip] = React.useState(false)
	const [showDeleteTooltip, setShowDeleteTooltip] = React.useState(false)
	const { markExistingNotificationRead, deleteExistingNotification } = useNotificationsHook()
	const unread = notification.status === NotificationStatus.UNREAD
	const createdDate = new Date(notification.created_date)

	const onNotificationClick = React.useCallback(() => {
		if (unread) {
			markExistingNotificationRead({
				request_id: uuidv4(),
				user_id: userId,
				notification_id: notification.id
			})
		}
	}, [unread])

	const avatarProps = React.useMemo(() => {
		if (notification.trigger_user_photo_url) {
			return { src: notification.trigger_user_photo_url }
		}
		return { icon: <UserOutlined /> }
	}, [notification.trigger_user_photo_url])

	return (
		<div
			className="notification-card"
			onClick={onNotificationClick}
			onMouseEnter={_ => {
				if (!unread) {
					setShowDeleteButton(true)
				}
			}}
			onMouseLeave={_ => {
				if (!unread) {
					setShowDeleteButton(false)
				}
			}}
		>
			<Row style={{ gap: 12 }}>
				<Col span={24}>
					<div className="notification-card-ago-badge-container">
						<NotificationDaysAgo notificationDate={createdDate} />
						{unread && (
							<div
								className="notification-badge-container"
								onMouseEnter={() => {
									setShowUnreadTooltip(true)
								}}
								onMouseLeave={() => {
									setShowUnreadTooltip(false)
								}}
								style={{ cursor: "pointer" }}
							>
								<Tooltip title="Mark as read" placement="bottom" visible={showUnreadTooltip}>
									<UnreadNotificationIcon />
								</Tooltip>
							</div>
						)}
						{showDeleteButton && (
							<div
								className="notification-badge-container"
								onMouseEnter={() => {
									setShowDeleteTooltip(true)
								}}
								onMouseLeave={() => {
									setShowDeleteTooltip(false)
								}}
								style={{ cursor: "pointer" }}
								onClick={() => {
									deleteExistingNotification({
										request_id: uuidv4(),
										user_id: userId,
										notification_id: notification.id
									})
								}}
							>
								<Tooltip title="Delete" placement="bottom" visible={showDeleteTooltip}>
									<DeleteNotificationIcon />
								</Tooltip>
							</div>
						)}
					</div>
				</Col>
				<Col span={24}>
					<div className="notification-card-avatar-text-container">
						<Avatar className="notification-card-avatar" size={48} {...avatarProps} />
						<div className="notification-card-message-container">
							<span className="notification-card-message">
								<NotificationMessage html_string={notification.html_text} />
							</span>
						</div>
					</div>
				</Col>
				<Col span={24}>
					<NotificationAction notification={notification} />
				</Col>
			</Row>
		</div>
	)
}

export default Notification
